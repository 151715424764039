import React from 'react';
import { Layout } from '@components';
import styled from 'styled-components';
import { mixins, Main } from '@styles';

const Container = styled(Main)`
  h1 {
    max-width: 700px;
  }
  a {
    ${mixins.button};
    margin-top: 50px;
  }
`;

const IndexPage = () => (
  <Layout>
    <Container>
      <h1>Please click the link below if you are not redirected within a few seconds...</h1>
      <a href="https://www.upstatement.com/jobs/">Continue to upstatement.com</a>
    </Container>
  </Layout>
);

export default IndexPage;
